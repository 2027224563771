import React, { forwardRef, Key, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { IDto, IPaginatedListDto, IParameter, IServiceClient } from '../api/client-interfaces';
import { Button, Dropdown, Empty, Flex, Menu, MenuProps, Popconfirm, Skeleton, Space, Table, Tooltip } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue } from 'react-table';
import { SorterResult } from 'antd/lib/table/interface';

import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
	EditOutlined,
	EyeOutlined,
	MoreOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import { ActionIconButton } from './StyledComponents';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

export type GenericTableProps<T extends IDto, TClient extends IServiceClient> = {
	client: TClient;
	defaultParameter: IParameter;
	defaultPaginatedListDto: IPaginatedListDto<T>;
	dataColumns: ColumnType<T>[];
	toDetails?: (row: T) => string;
	toEdit?: (row: T) => string;
	onDelete?: (row: T) => void;
	showDelete?: (row: T) => boolean;
	deleteTooltipText?: (row: T) => string | undefined;
	showEdit?: (row: T) => boolean;
	onFailure?: (error: unknown) => void;
	selectable?: boolean;
	selectedAction?: { callback: (rows: T[]) => Promise<void>; buttonText: string };
	checkboxEnabledCondition?: (record: T) => boolean;
	actionColumnWidth?: number | string;
	customActionsPrefix?: (value: number | string, record: T) => React.ReactNode;
	customActionsSuffix?: (value: number | string, record: T) => React.ReactNode;
	refreshCallback?: () => void;
	rowClassName?: string | ((record: IDto, index: number, indent: number) => string);
	rowKeyField?: string;
};

export type GenericTableHandle = {
	refresh: (refreshParameter?: IParameter, clearSelection?: boolean) => void;
};

type RowKeyType = number | string;

function GenericTableInner<TDto extends IDto, TClient extends IServiceClient>(
	{
		client,
		defaultParameter,
		defaultPaginatedListDto,
		dataColumns,
		toDetails,
		onDelete,
		showDelete,
		deleteTooltipText,
		toEdit,
		showEdit,
		onFailure,
		selectable,
		selectedAction,
		checkboxEnabledCondition,
		actionColumnWidth,
		customActionsPrefix,
		customActionsSuffix,
		refreshCallback,
		rowClassName,
		rowKeyField,
	}: GenericTableProps<TDto, TClient>,
	ref: React.Ref<GenericTableHandle>
): JSX.Element {
	const [data, setData] = useState(defaultPaginatedListDto);
	const [parameter, setParameter] = useState(defaultParameter);
	const [refreshDebounce, setRefreshDebounce] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [isFailed, setFailed] = useState(false);
	const [selectedRows, setSelectedRows] = useState(new Map<number | string, TDto>());
	const [selectedRowKeys, setSelectedRowKeys] = useState<(number | string)[]>([]);
	const disableSelectedAction = selectedRows.size < 1;
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

	useImperativeHandle(ref, () => ({
		refresh(refreshParameter?: IParameter, clearSelection?: boolean) {
			if (clearSelection && selectedRows.size > 0) {
				setSelectedRowKeys([]);
				setSelectedRows((map) => {
					map.clear();
					return map;
				});
			}
			if (refreshParameter) {
				setLoading(true);
				for (const refreshParameterKey in refreshParameter) {
					if ((refreshParameter as never)[refreshParameterKey] === undefined) {
						delete (refreshParameter as never)[refreshParameterKey];
					}
				}
				setParameter((parameter) => {
					return { ...defaultParameter, ...refreshParameter, orderBy: parameter.orderBy };
				});
			}
			refreshCallback?.();
		},
	}));
	const columns: ColumnType<TDto>[] = [
		...dataColumns,
		{
			title: 'Actions',
			dataIndex: 'actions',
			width: `${actionColumnWidth ?? 200}`,
			fixed: 'right',
			render: (value: number | string, record: TDto) => (
				<Space>
					{isTabletOrMobile ? (
						<Dropdown
							dropdownRender={(menu: React.ReactNode) => (
								<Menu>
									<Flex gap="middle" vertical>
										{customActionsPrefix?.(value, record) ?? []}
										{toDetails && (
											<Link to={toDetails(record)}>
												<Button title="Details" icon={<EyeOutlined />} />
											</Link>
										)}
										{toEdit && showEdit && showEdit(record) && (
											<Link to={toEdit(record)}>
												<Button title="Bearbeiten" icon={<EditOutlined />} />
											</Link>
										)}
										{onDelete && showDelete && showDelete(record) && (
											<Popconfirm
												title={deleteTooltipText?.(record) ?? 'Eintrag wirklich löschen?'}
												onConfirm={() => onDelete(record)}
												okText="Löschen"
												cancelText="Abbrechen">
												<Tooltip title="Löschen">
													<Button size={'large'} icon={<DeleteOutlined />} />
												</Tooltip>
											</Popconfirm>
										)}
										{customActionsSuffix?.(value, record) ?? []}
									</Flex>
								</Menu>
							)}
							trigger={['click']}>
							<Button icon={<MoreOutlined />} />
						</Dropdown>
					) : (
						<>
							{customActionsPrefix?.(value, record) ?? []}
							{toDetails && (
								<Link to={toDetails(record)}>
									<Button title="Details" icon={<EyeOutlined />} />
								</Link>
							)}
							{toEdit && showEdit && showEdit(record) && (
								<Link to={toEdit(record)}>
									<Button title="Bearbeiten" icon={<EditOutlined />} />
								</Link>
							)}
							{onDelete && showDelete && showDelete(record) && (
								<Popconfirm
									title={deleteTooltipText?.(record) ?? 'Eintrag wirklich löschen?'}
									onConfirm={() => onDelete(record)}
									okText="Löschen"
									cancelText="Abbrechen">
									<Tooltip title="Löschen">
										<Button size={'large'} icon={<DeleteOutlined />} />
									</Tooltip>
								</Popconfirm>
							)}
							{customActionsSuffix?.(value, record) ?? []}
						</>
					)}
				</Space>
			),
		},
	];

	const fetchData = useCallback(async () => {
		try {
			const data = await client.find<TDto>(parameter);
			setData(data);
			setFailed(false);
		} catch (e) {
			setFailed(true);
			onFailure?.(e);
		}
	}, [client, onFailure, parameter]);

	useEffect(() => {
		fetchData().finally(() => setLoading(false));
	}, [fetchData]);

	const getRowId = useCallback(
		(row: TDto) => {
			return rowKeyField ? (row as never)[rowKeyField] : row.id;
		},
		[rowKeyField]
	);

	const updateOrDeleteRow = useCallback(
		(
			newSelectedRows: Map<number | string, TDto>,
			row: TDto,
			existingRow: TDto,
			rowId: number | string,
			changed: boolean
		) => {
			if (checkboxEnabledCondition?.(row)) {
				if (JSON.stringify(row) !== JSON.stringify(existingRow)) {
					newSelectedRows.set(rowId, row);
					changed = true;
				}
			} else {
				newSelectedRows.delete(rowId);
				changed = true;
			}
			return changed;
		},
		[checkboxEnabledCondition]
	);

	const updateSelectedRows = useCallback(() => {
		const newSelectedRows = new Map<number | string, TDto>(selectedRows);
		let changed = false;

		if (data.items) {
			for (const row of data.items) {
				const rowId = getRowId(row);
				if (!rowId) continue;

				const existingRow = newSelectedRows.get(rowId);
				if (existingRow) {
					changed = updateOrDeleteRow(newSelectedRows, row, existingRow, rowId, changed);
				}
			}
		}

		if (changed) {
			setSelectedRows(newSelectedRows);
			setSelectedRowKeys(Array.from(newSelectedRows.keys()));
		}
	}, [selectedRows, data.items, getRowId, updateOrDeleteRow]);

	useEffect(() => {
		if (!selectable) return;
		updateSelectedRows();
	}, [checkboxEnabledCondition, data.items, rowKeyField, selectable, selectedRows, updateSelectedRows]);

	const onChangePage = (
		newParameter: IParameter,
		changed: boolean,
		page: number,
		pageSize: number
	): [IParameter, boolean] => {
		return [
			{ ...newParameter, pageSize: pageSize, pageNumber: page },
			changed || newParameter.pageNumber !== page || newParameter.pageSize !== pageSize,
		];
	};

	const onSort = (
		newParameter: IParameter,
		changed: boolean,
		selectedColumn: ColumnType<TDto> | undefined,
		sortDirection: 'asc' | 'desc'
	): [IParameter, boolean] => {
		if (selectedColumn?.dataIndex !== undefined) {
			const orderBy = selectedColumn?.dataIndex + ' ' + sortDirection;
			return [{ ...newParameter, orderBy }, changed || newParameter.orderBy !== orderBy];
		} else if (parameter.orderBy !== undefined) return [{ ...newParameter, orderBy: undefined }, true];
		return [newParameter, changed];
	};

	const handlePerRowsChange = (current: number, size: number) => {
		setLoading(true);
		setParameter((parameter) => {
			return { ...parameter, pageSize: size, pageNumber: current };
		});
	};

	const addNewRows = (newSelectedRows: Map<RowKeyType, TDto>, newRows: TDto[], changed: boolean) => {
		for (const row of newRows) {
			const rowId = rowKeyField ? (row as never)[rowKeyField] : row.id;
			if (rowId) {
				newSelectedRows.set(rowId, row);
				changed = true;
			}
		}
		return changed;
	};

	const removeUnselectedRows = (newSelectedRows: Map<Key, TDto>, newRowKeys: Key[], changed: boolean) => {
		newSelectedRows.forEach((value, key, map) => {
			if (!newRowKeys.includes(key)) {
				map.delete(key);
				changed = true;
			}
		});
		return changed;
	};

	const onChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<TDto> | SorterResult<TDto>[]
	) => {
		setLoading(true);
		const firstSorter = Array.isArray(sorter) ? sorter[0] : sorter;
		let newParameter: IParameter = parameter;
		let changed = false;
		[newParameter, changed] = onChangePage(newParameter, changed, pagination?.current ?? 1, pagination.pageSize ?? 10);
		[newParameter, changed] = onSort(
			newParameter,
			changed,
			firstSorter.column,
			firstSorter.order === 'ascend' ? 'asc' : 'desc'
		);
		if (changed) setParameter(newParameter);
	};

	return (
		<>
			<Button
				disabled={isLoading || refreshDebounce}
				type="text"
				onClick={() => {
					setLoading(true);
					setRefreshDebounce(true);
					fetchData().finally(() => {
						setLoading(false);
						setTimeout(() => setRefreshDebounce(false), 2000);
					});
					refreshCallback?.();
				}}>
				<SyncOutlined spin={isLoading} />
				Liste aktualisieren...
			</Button>
			{selectable && selectedAction ? (
				<Space direction="horizontal">
					<Button
						disabled={isLoading || disableSelectedAction}
						onClick={() => {
							setLoading(true);
							selectedAction.callback(Array.from(selectedRows.values())).finally(() => {
								setSelectedRowKeys([]);
								setSelectedRows((map) => {
									map.clear();
									return map;
								});
							});
						}}
						type="primary">
						<CheckCircleOutlined />
						{selectedAction.buttonText}
					</Button>
					<Button
						disabled={isLoading || disableSelectedAction}
						onClick={() => {
							setSelectedRowKeys([]);
							setSelectedRows((map) => {
								map.clear();
								return map;
							});
						}}>
						<CloseCircleOutlined />
						Auswahl aufheben
					</Button>
					{!disableSelectedAction ? <span>{selectedRows.size} Einträge ausgewählt</span> : null}
				</Space>
			) : null}
			<Table<TDto>
				columns={
					isLoading
						? columns.map((column: any) => {
								return {
									...column,
									render: () => (
										<Skeleton loading={isLoading} title={{ style: { height: '1.455em' } }} paragraph={false} />
									),
								};
						  })
						: columns
				}
				dataSource={data?.items ?? []}
				rowKey="id"
				rowClassName={rowClassName}
				onChange={onChange}
				loading={isLoading}
				pagination={{
					disabled: isLoading,
					total: data.totalCount,
					hideOnSinglePage: true,
					showSizeChanger: true,
					onShowSizeChange: handlePerRowsChange,
					pageSizeOptions: [10, 20, 30, 40],
					defaultCurrent: 1,
					defaultPageSize: 10,
					current: parameter.pageNumber,
					pageSize: parameter.pageSize,
				}}
				scroll={{ x: 'max-content' }}
				size={'small'}
				locale={{
					emptyText: isLoading ? (
						<Skeleton loading={isLoading} title paragraph={false} />
					) : (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={isFailed ? 'Fehler beim Laden der Daten.' : 'Keine Daten vorhanden.'}
						/>
					),
				}}
				showSorterTooltip={false}
				rowSelection={
					!selectable
						? undefined
						: {
								// We unload previously selected keys on page change.
								// To keep unloaded data selected, we need to preserve selected
								// rows even if the data is gone.
								preserveSelectedRowKeys: true,
								selectedRowKeys,
								getCheckboxProps: (record) => ({ disabled: !checkboxEnabledCondition?.(record) }),
								onChange: (newRowKeys, newRows) => {
									const newSelectedRows = new Map(selectedRows);
									let changed = false;

									changed = addNewRows(newSelectedRows, newRows, changed);
									changed = removeUnselectedRows(newSelectedRows, newRowKeys, changed);

									if (changed) {
										setSelectedRowKeys(Array.from(newSelectedRows.keys()));
										setSelectedRows(newSelectedRows);
									}
								},
								hideSelectAll: true,
						  }
				}
			/>
		</>
	);
}

const GenericTable = forwardRef(GenericTableInner) as <TDto extends IDto, TClient extends IServiceClient>(
	props: GenericTableProps<TDto, TClient> & { ref?: React.Ref<GenericTableHandle> }
) => ReturnType<typeof GenericTableInner>;

export default GenericTable;
