import { useContext, useEffect } from 'react';
import { SignalRContext } from './SignalRProvider';

const useSignalREffect = (eventName: string, eventHandler: (...args: any[]) => void) => {
	const { connection } = useContext(SignalRContext);

	useEffect(() => {
		if (connection) {
			connection.on(eventName, eventHandler);

			return () => {
				connection.off(eventName, eventHandler);
			};
		}
	}, [connection, eventName, eventHandler]);
};

export default useSignalREffect;
