import React, { useRef } from 'react';
import { QuizClient, QuizDto, QuizParameter } from '../api/client';
import GenericTable, { GenericTableHandle } from '../components/GenericTable';
import { quizClient } from '../api';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Popconfirm, Row, Space, Tooltip, Typography } from 'antd';
import { BookOutlined, CheckCircleOutlined, RedoOutlined, RocketOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';
import { openNotification } from '../App';

const dataColumns: ColumnType<QuizDto>[] = [
	{
		title: 'Title',
		dataIndex: 'title',
		sorter: true,
		width: 250,
		defaultSortOrder: 'ascend',
		fixed: 'left',
	},
	{
		title: 'Description',
		width: 150,
		dataIndex: 'description',
		sorter: true,
	},
	{
		title: 'Learning Progress',
		width: 200,
		sorter: true,
		render: (_value, record: QuizDto) => {
			const isAllAnswered = record.answeredQuestions === record.totalQuestions && record.totalQuestions > 0;
			return (
				<div>
					{`${record.answeredQuestions}/${record.totalQuestions}`}
					{isAllAnswered ?? <CheckCircleOutlined style={{ color: 'green', marginLeft: 8 }} />}
				</div>
			);
		},
	},
];

const QuizOverviewPage: React.FC = () => {
	const navigate = useNavigate();
	const tableRef = useRef<GenericTableHandle>(null);
	const handleButtonClick = (id: number, mode: string) => {
		navigate(`/overview/quiz/${id}/1/${mode}`);
	};

	const handleDelete = async (item: QuizDto) => {
		await quizClient.delete(item);

		tableRef.current?.refresh(new QuizParameter(), true);
	};

	const handleReset = async (quizId: number) => {
		try {
			localStorage.removeItem(`quiz-${quizId}-learning-answers`);
			await quizClient.resetUserAnswers(quizId);
			openNotification('Success', 'Quiz answers have been reset.', 'success');
			tableRef.current?.refresh(new QuizParameter(), true);
		} catch (error) {
			openNotification('Error', 'Failed to reset quiz answers.', 'error');
		}
	};

	const getButtons = (_value: string | number, record: QuizDto) => {
		return (
			<Space>
				<Tooltip title="Reset Learning Answers">
					<Popconfirm
						title="Are you sure you want to reset the learning answers for this quiz?"
						onConfirm={() => handleReset(record.id)}
						okText="Yes"
						cancelText="No">
						<Button className="quiz-button" size={'large'} icon={<RedoOutlined />} />
					</Popconfirm>
				</Tooltip>
				<Tooltip title="Learning Mode">
					<Button
						className="quiz-button"
						size={'large'}
						icon={<BookOutlined />}
						onClick={() => handleButtonClick(record.id, 'learning')}
					/>
				</Tooltip>
				<Tooltip title="Test Mode">
					<Button
						className="quiz-button"
						size={'large'}
						danger
						icon={<RocketOutlined />}
						onClick={() => handleButtonClick(record.id, 'test')}
					/>
				</Tooltip>
			</Space>
		);
	};

	return (
		<div className="container">
			<Row justify="center">
				<Col lg={100}>
					<Card>
						<Typography.Title level={3}>Quiz Overview</Typography.Title>
						<GenericTable<QuizDto, QuizClient>
							ref={tableRef}
							client={quizClient}
							dataColumns={dataColumns}
							actionColumnWidth={500}
							defaultParameter={{
								pageNumber: 1,
								pageSize: 10,
								orderBy: 'Title asc',
							}}
							defaultPaginatedListDto={{
								items: [],
								totalCount: 0,
							}}
							onDelete={handleDelete}
							showDelete={() => true}
							customActionsPrefix={getButtons}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default QuizOverviewPage;
