import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnswerDto, QuizDto, UserAnswerDto } from '../api/client';
import { quizClient } from '../api';
import { openNotification } from '../App';
import { QuizOverview } from '../components/QuizOverview';
import './QuizPage.css';
import { Quiz } from '../components/Quiz';

export const QuizPage = () => {
	const { quizId, questionNumber, mode } = useParams();
	const [quiz, setQuiz] = useState<QuizDto | null>(null);
	const [currentQuestion, setCurrentQuestion] = useState(parseInt(questionNumber ?? '') ?? 1);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const [savedAnswers, setSavedAnswers] = useState<{ [questionIndex: number]: number }>({});
	const testMode = mode === 'test';

	const loadUserAnswersFromDatabase = async (quizId: number) => {
		try {
			const userAnswers: UserAnswerDto[] = await quizClient.getUserAnswers(quizId);
			const answerMap: { [questionIndex: number]: number } = {};
			userAnswers.forEach((answer) => {
				answerMap[answer.questionId] = answer.answerId;
			});
			return answerMap;
		} catch (error) {
			openNotification('Error', 'Failed to load saved answers from database.', 'error');
			return {};
		}
	};

	const mergeSavedAnswers = (local: { [questionIndex: number]: number }, db: { [questionIndex: number]: number }) => {
		return { ...db, ...local };
	};

	function isEmptyObject(obj: any) {
		return Object.keys(obj).length === 0;
	}

	const fetchData = useCallback(async () => {
		try {
			setIsLoading(true);
			let data: QuizDto;

			const dbAnswers = await loadUserAnswersFromDatabase(parseInt(quizId ?? ''));
			const localAnswers = JSON.parse(localStorage.getItem(`quiz-${parseInt(quizId ?? '')}-${mode}-answers`) ?? '{}');
			const mergedAnswers = mergeSavedAnswers(localAnswers, dbAnswers);

			setSavedAnswers?.(mergedAnswers);

			if (isEmptyObject(mergedAnswers)) {
				data = await quizClient.readAndShuffle(parseInt(quizId ?? ''));
			} else {
				data = await quizClient.read(parseInt(quizId ?? ''));
			}
			setQuiz(data);

			const firstUnansweredQuestion = data.questions.findIndex((q) => !mergedAnswers[q.id]);
			setCurrentQuestion(firstUnansweredQuestion === -1 ? data.questions.length : firstUnansweredQuestion + 1);
		} catch (e) {
			openNotification('Failed', 'Loading quiz failed.', 'error');
		}
	}, [quizId]);

	const saveUserAnswerToDatabase = async (questionId: number, answerId: number) => {
		const selectedQuestion = quiz?.questions.find((q) => q.id === questionId);
		const isCorrect = selectedQuestion?.answers.find((ans) => ans.id === answerId)?.isCorrect ?? false;

		const userAnswerDto = {
			questionId: questionId,
			answerId: answerId,
			isCorrect: isCorrect,
		} as UserAnswerDto;

		try {
			await quizClient.saveUserAnswer(userAnswerDto);
		} catch (e) {
			openNotification('Error', 'Failed to save user answer', 'error');
		}
	};

	const handleButtonClick = async (answer: AnswerDto) => {
		const currentQuestionIndex = quiz?.questions[currentQuestion - 1].id ?? 0;

		if (testMode) {
			if (currentQuestion === quiz?.questions.length) {
				navigate(`/overview/quiz/${quiz.id}/${mode}/result`);
				return;
			}
			setCurrentQuestion((prevQuestion) => {
				return prevQuestion + 1;
			});
		}

		setSavedAnswers((prevSavedAnswers) => {
			const newSavedAnswers = { ...prevSavedAnswers, [quiz?.questions[currentQuestion - 1].id ?? 0]: answer.id };
			localStorage.setItem(`quiz-${quizId}-${mode}-answers`, JSON.stringify(newSavedAnswers));
			return newSavedAnswers;
		});

		if (!testMode) {
			await saveUserAnswerToDatabase(currentQuestionIndex, answer.id);
		}
	};

	const handleOverViewChange = (questionNumber: number) => {
		setCurrentQuestion(() => {
			const nextQuestion = questionNumber + 1;
			navigate(`/overview/quiz/${quiz?.id}/${nextQuestion}/${mode}`);
			return nextQuestion;
		});
	};

	useEffect(() => {
		fetchData().finally(() => setIsLoading(false));
	}, [fetchData]);

	useEffect(() => {
		navigate(`/overview/quiz/${quizId}/${currentQuestion}/${mode}`);
	}, [currentQuestion, navigate, quizId, mode]);

	return (
		<div className="container" style={{ justifyContent: 'unset' }}>
			<div className="overview">
				<QuizOverview
					quiz={quiz}
					mode={mode === 'test' ? 'test' : 'learning'}
					savedAnswers={savedAnswers}
					handleChange={handleOverViewChange}
					currentQuestion={currentQuestion}
				/>
			</div>
			<div className="card-container">
				<Quiz
					currentQuestion={currentQuestion}
					setCurrentQuestion={setCurrentQuestion}
					quiz={quiz}
					mode={mode === 'test' ? 'test' : 'learning'}
					isLoading={isLoading}
					savedAnswers={savedAnswers}
					setSavedAnswers={setSavedAnswers}
					handleButtonClick={handleButtonClick}
				/>
			</div>
		</div>
	);
};
