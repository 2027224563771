import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb, Layout, Menu, MenuProps, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React, { useState } from 'react';
import { Footer } from 'antd/es/layout/layout';
import {
	LogoutOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	UnorderedListOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import { useAuth } from './AuthProvider';
import { useViewportWidth } from '../hooks/useViewportWidth';
import { SignalRProvider } from './signalr/SignalRProvider';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';

const { Header, Content } = Layout;

type MenuItem = { label: string; key: string; path: string; icon: JSX.Element };

const menuItems: MenuItem[] = [
	{
		label: 'Overview',
		key: 'overview',
		path: '/overview',
		icon: <UnorderedListOutlined />,
	},
	{
		label: 'Import',
		key: 'import',
		path: '/import',
		icon: <UploadOutlined />,
	},
	{
		label: 'Logout',
		key: 'logout',
		path: '/logout',
		icon: <LogoutOutlined />,
	},
];

const getSelectedKeys = (path: string) => {
	const selectedItem = menuItems.find((item) => matchPath(path, item.path));
	return selectedItem ? [selectedItem.key] : [];
};

export const AppLayout = () => {
	const viewportWidth = useViewportWidth();
	const isMobileView = viewportWidth <= 768;

	const renderMobileMenu = () => (
		<Header style={{ padding: 0, background: colorBgContainer }}>
			<Menu
				mode="horizontal"
				defaultSelectedKeys={['1']}
				defaultOpenKeys={['sub1']}
				style={{ lineHeight: '64px' }}
				items={menuItems}
				onClick={handleMenuClick}>
				{menuItems.map((item) => (
					<Menu.Item key={item.key} icon={item.icon}>
						{item.label}
					</Menu.Item>
				))}
			</Menu>
		</Header>
	);

	const { isAuthenticated, signOut, token } = useAuth();
	const [collapsed, setCollapsed] = useState(false);
	const {
		token: { colorBgContainer },
	} = theme.useToken();
	const location = useLocation();
	const navigate = useNavigate();
	const selectedKeys = getSelectedKeys(location.pathname);

	if (!isAuthenticated) navigate('/sign-in');

	const getBreadcrumbs = () => {
		const pathNames = location.pathname.split('/').filter((x) => x);

		return pathNames.map((value, index) => {
			const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
			const menuItem = menuItems.find((item) => item.path === routeTo);
			const title = menuItem ? menuItem.label : value;

			return {
				title,
				href: routeTo,
			} as ItemType;
		});
	};

	const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
		const { path } = menuItems.find((item) => item.key === key) || {};
		if (path === '/logout') {
			signOut?.();
			navigate('/sign-in');
		} else if (path) {
			navigate(path);
		}
	};

	return isAuthenticated ? (
		<SignalRProvider
			connectEnabled={isAuthenticated}
			token={token ?? ''}
			url={(process.env.REACT_APP_API_BASE_URL ?? '/') + process.env.REACT_APP_SIGNALR_PATH ?? ''}>
			<Layout style={{ minHeight: '100vh' }}>
				{isMobileView ? (
					renderMobileMenu()
				) : (
					<Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
						<div style={{ height: 32, margin: 16, background: 'rgba(255, 255, 255, 0.2)' }} />
						<Menu
							mode="inline"
							selectedKeys={selectedKeys}
							defaultSelectedKeys={['1']}
							defaultOpenKeys={['sub1']}
							style={{ height: '100%', borderRight: 0 }}
							items={menuItems}
							onClick={handleMenuClick}
						/>
					</Sider>
				)}
				<Layout className="site-layout">
					{!isMobileView && (
						<Header style={{ padding: 0, background: colorBgContainer }}>
							{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
								className: 'trigger',
								onClick: () => setCollapsed(!collapsed),
							})}
						</Header>
					)}
					<Content
						style={{
							margin: '0px 16px',
							padding: 5,
							minHeight: 280,
						}}>
						<Breadcrumb style={{ margin: '16px 0' }} items={getBreadcrumbs()} />
						<Outlet />
					</Content>
					<Footer style={{ textAlign: 'center' }}>Scan and Learn ©2024 Created by Winkler KJL GmbH</Footer>
				</Layout>
			</Layout>
		</SignalRProvider>
	) : null;
};
