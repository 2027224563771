import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Row, Col, Card, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { authenticationClient } from '../api';
import { LoginRequest } from '../api/client';
import { useAuth } from '../components/AuthProvider';
import { openNotification } from '../App';

const { Title } = Typography;

export const SignInPage = () => {
	const { signIn, isAuthenticated } = useAuth();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const onSubmit = async (values: LoginRequest) => {
		try {
			setLoading(true);
			const result = await authenticationClient.postLogin({
				email: values.email,
				password: values.password,
			} as LoginRequest);

			if (
				signIn?.({
					token: result.accessToken,
					tokenType: 'Bearer',
					expiresIn: result.expiresIn,
					refreshToken: result.refreshToken,
				})
			) {
				navigate('/import');
				openNotification('Success', 'Sign in success', 'success');
			} else {
				openNotification('Failed', 'Sign in failed', 'error');
			}
		} catch (e) {
			openNotification('Failed', 'Sign in failed', 'error');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (isAuthenticated) {
			const redirectUrl = localStorage.getItem('redirectURL');
			if (redirectUrl && redirectUrl !== '/sign-in') {
				navigate(redirectUrl);
			} else {
				navigate('/import');
			}
		}
	}, [isAuthenticated, navigate]);

	const handleRegisterClick = () => {
		navigate('/register');
	};

	return (
		<Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
			<Col>
				<Card style={{ width: 300 }}>
					<Title level={2} style={{ textAlign: 'center' }}>
						Sign In
					</Title>
					<Form onFinish={onSubmit}>
						<Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
							<Input type="email" placeholder="Email" />
						</Form.Item>
						<Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
							<Input.Password placeholder="Password" />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
								Sign In
							</Button>
						</Form.Item>
					</Form>
					<Row justify="center">
						<Col>
							<Typography.Link onClick={handleRegisterClick}>Don't have an account? Register here</Typography.Link>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>
	);
};
