import React from 'react';
import { Button, Form, Input, Row, Col, Card, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RegisterRequest } from '../api/client';
import { authenticationClient } from '../api';

const { Title } = Typography;

export const RegisterPage = () => {
	const navigate = useNavigate();

	const onSubmit = async (values: RegisterRequest) => {
		try {
			await authenticationClient.postRegister({
				email: values.email,
				password: values.password,
			} as RegisterRequest);

			navigate('/sign-in');
		} catch (e) {
			console.error(e);
		}
	};

	const handleSignInClick = () => {
		navigate('/signin');
	};

	return (
		<Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
			<Col>
				<Card style={{ width: 300 }}>
					<Title level={2} style={{ textAlign: 'center' }}>
						Register
					</Title>
					<Form onFinish={onSubmit}>
						<Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
							<Input type="email" placeholder="Email" />
						</Form.Item>
						<Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
							<Input.Password placeholder="Password" />
						</Form.Item>
						<Form.Item
							name="confirmPassword"
							rules={[
								{ required: true, message: 'Please confirm your password!' },
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error('The two passwords do not match!'));
									},
								}),
							]}>
							<Input.Password placeholder="Confirm Password" />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit" style={{ width: '100%' }}>
								Register
							</Button>
						</Form.Item>
					</Form>
					<Row justify="center">
						<Col>
							<Typography.Link onClick={handleSignInClick}>Already have an account? Sign in here</Typography.Link>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>
	);
};
