import React, { useState } from 'react';
import { Typography, InputNumber, Space, Card, Alert, Progress, Input, Spin, Select, UploadFile } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { importClient } from '../api';
import { useNavigate } from 'react-router-dom';
import { openNotification } from '../App';
import Dragger from 'antd/es/upload/Dragger';
import useSignalREffect from '../components/signalr/useSignalREffect';
import { UploadChangeParam } from 'antd/es/upload';

type ProgressData = {
	percent: number;
	status: 'normal' | 'exception' | 'active' | 'success';
};

const languages = [
	{ value: 'German' },
	{ value: 'English' },
	// Add more languages here
];

const ImportPage = () => {
	const navigate = useNavigate();
	const [quizName, setQuizName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [startPage, setStartPage] = useState<number | undefined>(undefined);
	const [endPage, setEndPage] = useState<number | undefined>(undefined);
	const [language, setLanguage] = useState<string>(languages[0].value);
	const [progressData, setProgressData] = useState<ProgressData>({ percent: 0, status: 'active' });
	const [loading, setLoading] = useState(false);

	useSignalREffect('ProgressUpdate', (data) => {
		setProgressData({
			percent: data.percent,
			status: data.status,
		});
	});

	const handleUpload = async (info: UploadChangeParam) => {
		if (loading) return;
		try {
			setLoading(true);
			await importClient.uploadFiles(
				quizName,
				description,
				language,
				startPage,
				endPage,
				info.fileList.map((x) => {
					return {
						data: x.originFileObj as File,
						fileName: x.name,
					};
				})
			);
			openNotification('Success', 'Files uploaded successfully!', 'success');
		} catch (error) {
			console.error(error);
			openNotification('Failed', 'An error occurred while uploading files.', 'error');
		} finally {
			setLoading(false);
			navigate('/overview');
		}
	};

	return (
		<div className="container">
			<Card style={{ width: '800px' }}>
				<Typography.Title level={3}>Import PDFs</Typography.Title>
				<Input
					placeholder="Quiz Name"
					disabled={loading}
					onChange={(e) => setQuizName(e.target.value)}
					style={{ marginBottom: '1rem' }}
				/>
				<Input.TextArea
					placeholder="Description"
					disabled={loading}
					onChange={(e) => setDescription(e.target.value)}
					style={{ marginBottom: '1rem' }}
				/>
				<Alert
					type="info"
					message="Optional: Specify Start and End Pages"
					description="You don't need to input the start or end page, but the process of generating your quesitions will take longer due to more pages and it will even create questions for summary pages please keep that in mind."
					style={{ marginBottom: '1rem' }}
				/>
				<Space style={{ marginBottom: '1rem' }}>
					<InputNumber
						min={1}
						placeholder="Start Page"
						disabled={loading}
						onChange={(value) => setStartPage(value as number)}
					/>
					<InputNumber
						min={1}
						placeholder="End Page"
						disabled={loading}
						onChange={(value) => setEndPage(value as number)}
					/>
					<Select defaultValue={language} disabled={loading} onChange={setLanguage} options={languages} />
				</Space>
				{loading && (
					<>
						<div style={{ textAlign: 'center', marginTop: '1rem' }}>
							<Spin size="large" />
							<Typography.Title level={4} style={{ marginTop: '1rem' }}>
								Your questions are being generated. This may take a few minutes.
							</Typography.Title>
						</div>
						<Progress percent={progressData.percent} status={progressData.status} />
					</>
				)}
				<Dragger
					name={'file'}
					accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
					multiple={true}
					listType="picture"
					disabled={loading || !quizName}
					onChange={handleUpload}>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>
					<p className="ant-upload-text">Click or drag file to this area to upload</p>
					<p className="ant-upload-hint">
						Support for a single upload. Strictly prohibited from uploading company data or other banned files.
					</p>
				</Dragger>
			</Card>
		</div>
	);
};

export default ImportPage;
