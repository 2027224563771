import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import React from 'react';
import { QuizPage } from './pages/QuizPage';
import ImportPage from './pages/ImportPage';
import OverviewPage from './pages/OverviewPage';
import { SignInPage } from './pages/SignInPage';
import { ConfigProvider, notification } from 'antd';
import { RegisterPage } from './pages/RegisterPage';
import { AppLayout } from './components/AppLayout';
import { ProtectedRoute } from './components/ProtectedRoute';
import { ResultPage } from './pages/ResultPage';

export const openNotification = (
	title: React.ReactNode,
	description: React.ReactNode,
	type: 'success' | 'info' | 'error' | 'warning'
) => {
	notification.open({
		message: title,
		description,
		type,
		duration: 5,
		placement: 'topRight',
	});
};

export const App = () => {
	const router = createBrowserRouter([
		{
			path: process.env.PUBLIC_URL ?? '/',
			element: <AppLayout />,
			errorElement: 'Ein Fehler ist aufgetreten.',
			children: [
				{
					index: true,
					element: <Navigate to={`/overview`} replace />,
				},
				{
					path: 'import',
					handle: { crumb: 'Import' },
					element: <ProtectedRoute element={<ImportPage />} />,
				},
				{
					path: 'overview',
					handle: { crumb: 'Overview' },
					element: <ProtectedRoute element={<OverviewPage />} />,
				},
				{
					path: 'overview/quiz/:quizId/:questionNumber/:mode',
					handle: { crumb: 'Quiz' },
					element: <ProtectedRoute element={<QuizPage />} />,
				},
				{
					path: 'overview/quiz/*',
					element: <ProtectedRoute element={<Navigate to="/overview" />} />,
				},
				{
					path: 'overview/quiz/:quizId/:mode/result',
					handle: { crumb: 'Quiz' },
					element: <ProtectedRoute element={<ResultPage />} />,
				},
			],
		},
		{
			path: 'sign-in',
			element: <SignInPage />,
		},
		{
			path: 'register',
			element: <RegisterPage />,
		},
	]);

	return (
		<ConfigProvider locale={{ locale: 'de_DE', Pagination: { items_per_page: '/ Seite' } }}>
			<RouterProvider router={router} />
		</ConfigProvider>
	);
};
