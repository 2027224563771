import { Alert, Button, Card, Space, Spin, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { AnswerDto, QuizDto } from '../api/client';
import { useNavigate } from 'react-router-dom';
import { FireOutlined } from '@ant-design/icons';

type QuizProps = {
	quiz: QuizDto | null;
	currentQuestion: number;
	setCurrentQuestion: (currentQuestion: number) => void;
	isLoading: boolean;
	mode: 'test' | 'learning';
	savedAnswers: { [questionIndex: number]: number };
	setSavedAnswers?: (userAnswers: { [questionIndex: number]: number }) => void;
	handleButtonClick?: (answer: AnswerDto) => void;
};

export const Quiz = (props: QuizProps) => {
	const {
		quiz,
		currentQuestion,
		setCurrentQuestion,
		isLoading,
		mode,
		handleButtonClick,
		savedAnswers,
		setSavedAnswers,
	} = props;
	const [answerResult, setAnswerResult] = useState<null | 'success' | 'error'>(null);
	const navigate = useNavigate();
	const testMode = mode === 'test';
	const [winCount, setWinCount] = useState(0);
	const [lossCount, setLossCount] = useState(0);

	const getButtonStyle = (answer: AnswerDto) => {
		if (!testMode) {
			if (savedAnswers[quiz?.questions[currentQuestion - 1].id ?? 0] === answer.id) {
				if (answerResult === 'success') {
					return {
						color: '#4096ff',
						borderColor: '#4096ff',
						backgroundColor: '#f6ffed',
					};
				} else if (answerResult === 'error') {
					return {
						color: '#4096ff',
						borderColor: '#4096ff',
						backgroundColor: '#fff1f0',
					};
				}
			} else if (savedAnswers[quiz?.questions[currentQuestion - 1].id ?? 0] && answer.isCorrect) {
				return {
					borderColor: '#b7eb8f',
					backgroundColor: '#f6ffed',
				};
			}
		} else {
			if (savedAnswers[quiz?.questions[currentQuestion - 1].id ?? 0] === answer.id) {
				return {
					color: '#4096ff',
					borderColor: '#4096ff',
				};
			}
		}

		return {};
	};

	const handlePrevQuestion = () => {
		setCurrentQuestion(currentQuestion - 1);
	};

	const handleNextQuestion = () => {
		setCurrentQuestion(currentQuestion + 1);
	};

	useEffect(() => {
		setAnswerResult(null);

		let win = 0;
		let loss = 0;

		const lastFiveKeys = Object.keys(savedAnswers).slice(-5);

		lastFiveKeys.forEach((questionId) => {
			const answerId = savedAnswers[parseInt(questionId)];
			const question = quiz?.questions.find((q) => q.id === parseInt(questionId));
			const answer = question?.answers.find((a) => a.id === answerId);
			if (answer?.isCorrect) {
				win++;
			} else {
				loss++;
			}
		});

		setWinCount(win);
		setLossCount(loss);

		const currentQuestionId = quiz?.questions[currentQuestion - 1].id;
		if (currentQuestionId && savedAnswers[currentQuestionId] !== undefined) {
			const selectedAnswer = quiz?.questions[currentQuestion - 1].answers.find(
				(answer) => answer.id === savedAnswers[currentQuestionId]
			);

			if (selectedAnswer) {
				setAnswerResult(selectedAnswer.isCorrect ? 'success' : 'error');
			}
		}
	}, [currentQuestion, mode, quiz?.questions, quiz?.id, setSavedAnswers]);

	return (
		<Card
			className="card"
			title={
				<>
					<Typography.Title level={4}>{quiz?.title}</Typography.Title>
					<Typography.Title style={{ marginTop: '0', whiteSpace: 'normal' }} level={3}>
						{quiz?.questions[currentQuestion - 1].text}
					</Typography.Title>
					<Typography.Text>
						Question {currentQuestion} of {quiz?.questions.length}
					</Typography.Text>
					{winCount >= 5 && (
						<Tooltip title="You are on fire!">
							<FireOutlined style={{ color: 'red', fontSize: '24px', marginLeft: '1rem' }} />
						</Tooltip>
					)}
					{lossCount >= 5 && (
						<Tooltip title="One wrong answer will be removed to make it less difficult">
							<FireOutlined style={{ color: 'blue', fontSize: '24px', marginLeft: '1rem' }} />
						</Tooltip>
					)}
				</>
			}>
			<Spin spinning={isLoading}>
				<Space direction="vertical" size="large" style={{ width: '100%' }}>
					{quiz?.questions[currentQuestion - 1].answers
						.filter((answer, index, array) => {
							// Filter logic: remove one incorrect answer if lossCount >= 5
							if (lossCount >= 5 && !answer.isCorrect) {
								const incorrectAnswers = array.filter((ans) => !ans.isCorrect);
								// Find the first incorrect answer not selected by the user and remove it from rendering
								if (incorrectAnswers.findIndex((ans) => ans.id === answer.id) === 0) {
									return false;
								}
							}
							return true;
						})
						.map((answer) => (
							<Button
								key={answer.id}
								onClick={() => {
									if (answer.isCorrect) {
										setAnswerResult('success');
									} else {
										setAnswerResult('error');
									}
									handleButtonClick?.(answer);
								}}
								disabled={
									(testMode ? false : savedAnswers[quiz?.questions[currentQuestion - 1].id ?? 0] !== undefined) ||
									handleButtonClick === undefined
								}
								style={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									padding: '2rem',
									...getButtonStyle(answer),
								}}>
								<div
									style={{
										textAlign: 'left',
										whiteSpace: 'normal',
										overflowY: 'auto',
										maxHeight: '4rem',
									}}>
									{answer.text}
								</div>
							</Button>
						))}
				</Space>
				<div
					style={{
						marginTop: '16px',
						display: 'flex',
						justifyContent: 'space-between',
						flexFlow: currentQuestion === 1 ? 'row-reverse' : '',
					}}>
					{!testMode && currentQuestion > 1 && <Button onClick={handlePrevQuestion}>Previous</Button>}
					{!testMode && quiz && currentQuestion < quiz.questions.length && (
						<Button onClick={handleNextQuestion}>Next</Button>
					)}
					{quiz && currentQuestion === quiz.questions.length && handleButtonClick !== undefined && (
						<Button
							disabled={Object.keys(savedAnswers).length !== quiz?.questions.length}
							onClick={() => navigate(`/overview/quiz/${quiz.id}/${mode}/result`)}>
							Evaluate
						</Button>
					)}
				</div>
				{!testMode && answerResult === 'success' && (
					<Alert
						message="Correct!"
						description="You answered correctly."
						type="success"
						showIcon
						style={{ marginTop: '1rem' }}
					/>
				)}
				{!testMode && answerResult === 'error' && (
					<Alert
						message="Incorrect!"
						description="You answered incorrectly."
						type="error"
						showIcon
						style={{ marginTop: '1rem' }}
					/>
				)}
			</Spin>
		</Card>
	);
};
