import { Button, Card } from 'antd';
import { QuestionDto, QuizDto } from '../api/client';

export const QuizOverview = ({
	quiz,
	mode,
	savedAnswers,
	handleChange,
	currentQuestion,
}: {
	quiz: QuizDto | null;
	mode: 'test' | 'learning';
	savedAnswers: { [questionIndex: number]: number };
	handleChange: (questionIndex: number) => void;
	currentQuestion: number;
}) => {
	const getButtonStyle = (question: QuestionDto) => {
		const selectedAnswerId = savedAnswers[question.id];
		const selectedAnswer = question.answers.find((answer) => answer.id === selectedAnswerId);

		if (!selectedAnswer) {
			return null;
		}

		if (mode === 'test')
			return {
				backgroundColor: '#e6f4ff',
			};

		if (selectedAnswer.isCorrect) {
			return {
				borderColor: '#b7eb8f',
				backgroundColor: '#f6ffed',
			};
		}

		return {
			borderColor: '#ffccc7',
			backgroundColor: '#fff1f0',
		};
	};

	return (
		<Card>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(auto-fill, minmax(2rem, 1fr))',
					gridGap: '0.5rem',
					width: '100%',
				}}>
				{quiz &&
					quiz.questions.map((question, index) => {
						return (
							<Button
								key={question.id}
								onClick={() => handleChange(index)}
								style={{
									textAlign: 'center',
									whiteSpace: 'normal',
									padding: 0,
									width: '2rem',
									...getButtonStyle(question),
									borderColor: index === currentQuestion - 1 ? '#4096ff' : '',
									color: index === currentQuestion - 1 ? '#4096ff' : '',
								}}>
								{index + 1}
							</Button>
						);
					})}
			</div>
		</Card>
	);
};
