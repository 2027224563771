import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { useEffect } from 'react';

interface ProtectedRouteProps {
	element: JSX.Element;
}

export const ProtectedRoute = ({ element }: ProtectedRouteProps) => {
	const { isAuthenticated } = useAuth();

	return isAuthenticated ? element : <Navigate to="/sign-in" replace />;
};
