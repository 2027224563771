import { createContext, FC, ReactNode } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { useSignalR } from './useSignalR';

interface SignalRContextType {
	connection: HubConnection | null;
	connectionState: string;
	connectionError: Error | null;
}

interface SignalRProviderProps {
	children: ReactNode;
	url: string;
	token?: string;
	connectEnabled: boolean;
}

const defaultSignalRContext: SignalRContextType = {
	connection: null,
	connectionState: 'Disconnected',
	connectionError: null,
};

export const SignalRContext = createContext<SignalRContextType>(defaultSignalRContext);
export const SignalRProvider: FC<SignalRProviderProps> = ({ children, url, token, connectEnabled }) => {
	const signalR = useSignalR({ url, token, connectEnabled });
	return <SignalRContext.Provider value={signalR}>{children}</SignalRContext.Provider>;
};
