import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';

type AuthProviderProps = {
	children: ReactNode;
};

type AuthContextProps = {
	isAuthenticated: boolean;
	token: string | null;
	signIn: (props: SignInProps) => Promise<void>;
	signOut: () => void;
};

type SignInProps = {
	token: string;
	tokenType: string;
	expiresIn: number;
	refreshToken: string;
};

type DecodedToken = {
	exp: number;
};

const AuthContext = createContext<AuthContextProps | null>(null);

export const useAuth = () => {
	const context = useContext(AuthContext);
	const isAuthenticated = context?.isAuthenticated ?? false;
	const token = context?.token ?? null;
	const signIn = context?.signIn;
	const signOut = context?.signOut;
	return { isAuthenticated, token, signIn, signOut };
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
	const [token, setToken] = useState<string | null>(null);
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>();

	const handleSignIn = useCallback(async (props: SignInProps) => {
		const cookies = new Cookies();
		cookies.set('token', props.token, { maxAge: props.expiresIn, secure: true });
		setToken(props.token);
		setIsAuthenticated(true);
	}, []);

	const handleSignOut = useCallback(() => {
		setToken(null);
		const cookies = new Cookies();
		cookies.remove('token');
		setIsAuthenticated(false);
	}, []);

	useEffect(() => {
		const cookies = new Cookies();
		const storedToken = cookies.get('token');
		if (storedToken) {
			setToken(storedToken);
			setIsAuthenticated(true);
			localStorage.setItem('redirectURL', window.location.pathname);
		} else {
			handleSignOut();
		}
	}, [handleSignOut]);

	const value = useMemo(() => {
		return {
			isAuthenticated: isAuthenticated ?? false,
			token: token,
			signIn: handleSignIn,
			signOut: handleSignOut,
		};
	}, [handleSignIn, handleSignOut, isAuthenticated, token]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
