import { AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';

export abstract class ClientBase {
	protected transformOptions(options: AxiosRequestConfig): Promise<AxiosRequestConfig> {
		const cookies = new Cookies();
		const token = cookies.get('token');

		if (token) {
			const headers = {
				...options.headers,
				Authorization: `Bearer ${token}`,
			};

			return Promise.resolve({
				...options,
				headers,
			});
		}

		return Promise.resolve(options);
	}
}
