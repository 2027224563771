import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import i18n from 'i18next';

import deTranslation from './translations/de.json';
import { I18nextProvider } from 'react-i18next';
import { App } from './App';
import { AuthProvider } from './components/AuthProvider';

// Initialize i18n with translations
i18n.init({
	interpolation: { escapeValue: false },
	lng: 'de', // Set default language
	resources: {
		de: {
			translation: deTranslation,
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<AuthProvider>
				<App />
			</AuthProvider>
		</I18nextProvider>
	</React.StrictMode>
);
