import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Progress, Button, Card, Row, Col } from 'antd';
import { quizClient } from '../api';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import { QuizOverview } from '../components/QuizOverview';
import { Quiz } from '../components/Quiz';
import { QuizDto } from '../api/client';
import { openNotification } from '../App';

export const ResultPage = () => {
	const { quizId, mode } = useParams();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [isResetting, setIsResetting] = useState(false);
	const [correctAnswers, setCorrectAnswers] = useState(0);
	const [quiz, setQuiz] = useState<QuizDto | null>(null);
	const [totalQuestions, setTotalQuestions] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState(1);
	const [savedAnswers, setSavedAnswers] = useState<{ [questionIndex: number]: number }>({});

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const quiz = await quizClient.read(parseInt(quizId ?? ''));
			setQuiz(quiz);
			setTotalQuestions(quiz.questions.length);

			const savedAnswers = localStorage.getItem(`quiz-${quizId}-${mode}-answers`);
			if (savedAnswers) {
				const parsedAnswers = JSON.parse(savedAnswers);
				setSavedAnswers(parsedAnswers);
				let correctCount = 0;

				quiz.questions.forEach((question) => {
					const selectedAnswerId = parsedAnswers[question.id];
					const selectedAnswer = question.answers.find((answer) => answer.id === selectedAnswerId);
					if (selectedAnswer?.isCorrect) {
						correctCount++;
					}
				});

				setCorrectAnswers(correctCount);
			}
		};

		fetchData().finally(() => setIsLoading(false));
	}, [mode, quizId]);

	const scorePercentage = Math.round((correctAnswers / totalQuestions) * 100);

	const tryAgain = async () => {
		localStorage.removeItem(`quiz-${quizId}-${mode}-answers`);
		if (mode === 'learning') {
			setIsResetting(true);
			try {
				await quizClient.resetUserAnswers(quiz?.id ?? 0);
			} catch (error) {
				openNotification('Error', 'Failed to reset quiz answers.', 'error');
			}
			setIsResetting(false);
		}
		navigate(`/overview/quiz/${quizId}/1/${mode}`);
	};

	const getEmotionIcon = () => {
		if (scorePercentage >= 50) {
			return <SmileOutlined style={{ fontSize: '72px', color: '#52c41a' }} />;
		} else {
			return <FrownOutlined style={{ fontSize: '72px', color: '#f5222d' }} />;
		}
	};

	const handleOverViewChange = (questionNumber: number) => {
		setCurrentQuestion(() => {
			const nextQuestion = questionNumber + 1;
			navigate(`/overview/quiz/${quiz?.id}/${mode}/result`);
			return nextQuestion;
		});
	};

	return (
		<div className="container" style={{ width: '100%', justifyContent: 'center', flexFlow: 'wrap' }}>
			<Card style={{ alignSelf: 'self-start' }}>
				<Row justify="center">
					<Col span={24} style={{ textAlign: 'center' }}>
						<Typography.Title level={2}>Quiz Result</Typography.Title>
						{getEmotionIcon()}
						<Typography.Title level={4}>
							You got {correctAnswers} out of {totalQuestions} correct!
						</Typography.Title>
						<Progress type="circle" percent={scorePercentage} />
						<div style={{ marginTop: '2rem' }}>
							<Button type="primary" loading={isResetting} onClick={tryAgain}>
								Try Again
							</Button>
						</div>
					</Col>
				</Row>
			</Card>
			<div className="overview" style={{ alignSelf: 'self-start' }}>
				<QuizOverview
					quiz={quiz}
					mode={mode === 'test' ? 'test' : 'learning'}
					savedAnswers={savedAnswers}
					handleChange={handleOverViewChange}
					currentQuestion={currentQuestion}
				/>
			</div>
			<div className="card-container">
				<Quiz
					currentQuestion={currentQuestion}
					setCurrentQuestion={setCurrentQuestion}
					quiz={quiz}
					mode={mode === 'test' ? 'test' : 'learning'}
					isLoading={isLoading}
					savedAnswers={savedAnswers}
				/>
			</div>
		</div>
	);
};
