import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

interface UseSignalRProps {
	url: string;
	token?: string;
	connectEnabled: boolean;
}

export const useSignalR = ({ url, token, connectEnabled }: UseSignalRProps) => {
	const [connection, setConnection] = useState<HubConnection | null>(null);
	const [connectionState, setConnectionState] = useState<string>('Disconnected');
	const [connectionError, setConnectionError] = useState<Error | null>(null);

	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(url, {
				accessTokenFactory: () => token ?? '',
			})
			.withAutomaticReconnect()
			.build();

		setConnection(newConnection);
	}, [url, token]);

	useEffect(() => {
		if (connection && connectEnabled) {
			connection
				.start()
				.then(() => setConnectionState('Connected'))
				.catch((err) => {
					setConnectionState('Disconnected');
					setConnectionError(err);
				});

			connection.onreconnecting(() => setConnectionState('Reconnecting'));
			connection.onreconnected(() => setConnectionState('Connected'));
			connection.onclose((error) => {
				setConnectionState('Disconnected');
				setConnectionError(error ?? null);
			});

			return () => {
				connection.stop();
			};
		}
	}, [connection, connectEnabled]);

	return { connection, connectionState, connectionError };
};
